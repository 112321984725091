import * as React from 'react';
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { ReactComponent as ArrowDownSvg } from '~/assets/arrow-down.svg';
import { ReactComponent as CloseSvg } from '~/assets/close.svg';
import { Calendar24 } from '~/assets/calendar24';
import { useOnClickOutside } from '~/hooks';
import { allFilter, resetFilter, toggleFilter } from '~/redux/reducers/ReportsFiltersSlice';
import { useAppDispatch, useAppSelector } from '~/hooks/redux';
import moment from 'moment';
import { IPeriods, today, updateTimeSheetsPeriod } from '~/redux/modules/periodsModule';
import { EStatisticStep } from 'sb_manufacturing_front_api';
import { FORMAT_MOMENT } from '~/helpers/constants';
import FilterCol from '~/components/filters/FilterCol';

interface IReportsFilters {
  filterDay: any;
  setFilterDay: (filterDay: any) => void;
  stepsFilter: string;
  setStepsFilter: (value: string) => void;
}

interface ISteps {
  id: number;
  name: string;
  check: boolean;
  value: string;
}

export const StatisticsFilters = ({ filterDay, setFilterDay, setStepsFilter }: IReportsFilters) => {
  const momentFormat = 'YYYY/MM/DD';
  const { RangePicker } = DatePicker;
  const periodPopupRef = React.useRef<HTMLDivElement>(null);
  const stepsPopupRef = React.useRef<HTMLDivElement>(null);
  const filterPopupRef = React.useRef<HTMLDivElement>(null);
  const [dateDisable, setDateDisable] = React.useState(true);
  const [popupVisible, setPopupVisible] = React.useState(false);
  const [popupPeriodVisible, setPopupPeriodVisible] = React.useState(false);
  const [popupStepsVisible, setPopupStepsVisible] = React.useState(false);
  const [currentFilterName, setCurrentFilterName] = React.useState('');
  const [steps, setSteps] = React.useState<ISteps[]>([
    {
      id: 0,
      name: 'День',
      value: EStatisticStep.DAY,
      check: true,
    },
    {
      id: 1,
      name: 'Неделя',
      value: EStatisticStep.WEEK,
      check: false,
    },
    {
      id: 2,
      name: 'Месяц',
      value: EStatisticStep.MONTH,
      check: false,
    },
  ]);

  const { reportsFilters } = useAppSelector(state => state.reportsFilters);
  const { entities: periods } = useAppSelector(state => state.timesheetsPeriods);

  const checkedPeriod = React.useMemo(() => {
    const result = periods.find(period => period.check);
    if (result) return result;
    return {
      id: 1,
      name: 'Сегодня',
      value: today,
      check: true,
    };
  }, [periods]);

  const checkedStep = React.useMemo(() => {
    const result = steps.find(step => step.check);
    if (result) return result;
    return result;
  }, [steps]);

  const dispatch = useAppDispatch();

  const filterClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (popupVisible) {
      setPopupVisible(false);
    } else if (filterPopupRef.current) {
      filterPopupRef.current.style.left =
        event.currentTarget.offsetLeft + event.currentTarget.children[0].clientWidth + 25 + 'px';
      reportsFilters.map(obj => {
        if (event.currentTarget.dataset.name && obj.name === event.currentTarget.dataset.name) {
          setCurrentFilterName(event.currentTarget.dataset.name);
          filterPopupRef.current && filterPopupRef.current.classList.add('open');
          setPopupVisible(true);
        }
      });
    }
  };

  const periodClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (popupPeriodVisible) {
      return setPopupPeriodVisible(false);
    }
    if (periodPopupRef.current) {
      periodPopupRef.current.style.left =
        event.currentTarget.offsetLeft + event.currentTarget.children[0].clientWidth + 65 + 'px';
      periodPopupRef.current && periodPopupRef.current.classList.add('open');
      setPopupPeriodVisible(true);
    }
  };

  const stepsClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (popupStepsVisible) {
      return setPopupStepsVisible(false);
    }
    if (stepsPopupRef.current) {
      stepsPopupRef.current.style.left =
        event.currentTarget.offsetLeft + event.currentTarget.children[0].clientWidth + 65 + 'px';
      stepsPopupRef.current && stepsPopupRef.current.classList.add('open');
      setPopupStepsVisible(true);
    }
  };

  const filterCurrent = reportsFilters.find(filter => filter.name === currentFilterName);

  const onClose = () => {
    filterPopupRef.current && filterPopupRef.current.classList.remove('open');
    setPopupVisible(false);
  };

  const onPeriodClose = () => {
    periodPopupRef.current && periodPopupRef.current.classList.remove('open');
    setPopupPeriodVisible(false);
  };

  const onStepsClose = () => {
    stepsPopupRef.current && stepsPopupRef.current.classList.remove('open');
    setPopupStepsVisible(false);
  };

  useOnClickOutside(periodPopupRef, popupPeriodVisible ? onPeriodClose : () => {});
  useOnClickOutside(stepsPopupRef, popupStepsVisible ? onStepsClose : () => {});
  useOnClickOutside(filterPopupRef, popupVisible ? onClose : () => {});

  const checkChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number | null,
    radio: boolean,
  ) => {
    const name = String(event.target.dataset.filterName);
    dispatch(toggleFilter({ id, name, radio }));
  };

  const onPeriodChange = React.useCallback(
    ({ id, name, value }: IPeriods) => {
      if (checkedPeriod) {
        const { id: resId, name: resName, value: resVal } = checkedPeriod;
        dispatch(
          updateTimeSheetsPeriod({
            id: resId,
            name: resName,
            value: resVal,
            check: false,
          }),
        );
      }
      dispatch(updateTimeSheetsPeriod({ id, name, value, check: true }));
      setFilterDay(value.map((date: number) => moment(moment.unix(date).format(momentFormat))));
    },
    [periods],
  );

  const onStepsChange = React.useCallback(
    ({ id, value }: ISteps) => {
      if (!steps) return;
      const date = steps.map(item => {
        item.check = item.id === id;
        return item;
      });
      setSteps(date);
      setStepsFilter(value);
    },
    [steps],
  );

  const checkedFilters = React.useMemo(() => {
    const checkedFiltersArr = filterCurrent?.data.filter(el => el.check === true);
    return !!(checkedFiltersArr && checkedFiltersArr.length > 0);
  }, [filterCurrent]);

  const resetAll = (event: React.MouseEvent<HTMLDivElement>) => {
    const name = event.currentTarget.dataset.filter;
    if (!name) return;

    dispatch(resetFilter({ name }));
  };

  const chooseAll = (event: React.MouseEvent<HTMLDivElement>) => {
    const name = event.currentTarget.dataset.filter;
    if (!name) return;
    dispatch(allFilter({ name }));
  };

  const handleCalendarChange = (value: any) => {
    setDateDisable(false);
    const newDate: Array<number> = [];
    value.forEach((date: any) => {
      newDate.push(moment(date).unix());
    });
    setUnixToFilterDay(newDate);
  };

  const setUnixToFilterDay = (unixDate: Array<number>) => {
    setFilterDay(unixDate.map((date: number) => moment(moment.unix(date).format(momentFormat))));
  };

  const [start] = filterDay;

  return (
    <div className="list-table__row list-table__row_header_filters">
      <div>
        <div className="list-table__col list-users-table_col" onClick={periodClick}>
          <div className="active-filters">
            <span className="active-filters_span-active">Период: </span>
            <span className="active-filters__filter-name">{checkedPeriod.name}</span>
          </div>
          <ArrowDownSvg />
        </div>
      </div>
      <div>
        <div className="list-table__col list-users-table_col" onClick={stepsClick}>
          <div className="active-filters">
            <span className="active-filters_span-active">Шаг: </span>
            <span className="active-filters__filter-name">{checkedStep?.name}</span>
          </div>
          <ArrowDownSvg />
        </div>
      </div>

      <div style={{ width: '300px' }}>
        <button className="button_white__small button_date">
          <span>Дата:</span>
          <RangePicker
            locale={locale}
            placeholder={['', '']}
            separator={<span>→</span>}
            suffixIcon={<Calendar24 />}
            format={[FORMAT_MOMENT.DDMMYYYY]}
            value={filterDay}
            onChange={handleCalendarChange}
            allowClear={false}
            onOpenChange={e => setDateDisable(!e)}
            disabled={[false, dateDisable]}
            disabledDate={current =>
              current && current > moment(start).add(3, 'months').subtract(1, 'days')
            }
          />
        </button>
      </div>
      {reportsFilters.map((column, key) => {
        if (column.data.length <= 1) return null;
        return (
          <FilterCol key={key} column={column} filterClick={filterClick} filters={reportsFilters} />
        );
      })}
      <div className="filter-popup" ref={periodPopupRef}>
        <button className="filter-popup__close" onClick={onPeriodClose}>
          <CloseSvg />
        </button>
        <div className="filter-popup__name">Период</div>

        <div className="filter-popup__content">
          {periods.map((period, key) => {
            return (
              <div className="rf-content__line" key={key}>
                <input
                  type="radio"
                  onChange={() => {
                    onPeriodChange(period);
                  }}
                  name="period"
                  checked={period.check}
                />
                <span>{period.name}</span>
              </div>
            );
          })}
        </div>
      </div>

      <div className="filter-popup" ref={stepsPopupRef}>
        <button className="filter-popup__close" onClick={onStepsClose}>
          <CloseSvg />
        </button>
        <div className="filter-popup__name">Шаг</div>

        <div className="filter-popup__content">
          {steps.map((step, key) => {
            return (
              <div className="rf-content__line" key={key}>
                <input
                  type="radio"
                  onChange={() => {
                    onStepsChange(step);
                  }}
                  name="steps"
                  checked={step.check}
                />
                <span>{step.name}</span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="filter-popup" ref={filterPopupRef}>
        <button className="filter-popup__close" onClick={onClose}>
          <CloseSvg />
        </button>
        <div className="filter-popup__name">{filterCurrent?.title}</div>

        {!checkedFilters && !filterCurrent?.radio && (
          <div
            className="filter-popup__choose-all"
            onClick={chooseAll}
            data-filter={filterCurrent?.name}
          >
            Выбрать все
          </div>
        )}
        {checkedFilters && !filterCurrent?.radio && (
          <div className="filter-popup__reset" onClick={resetAll} data-filter={filterCurrent?.name}>
            Сбросить все
          </div>
        )}
        <div className="filter-popup__content">
          {filterCurrent?.data.map(
            (
              data: { id: number | null; name: string; deleted?: boolean; check: boolean },
              key: number,
            ) => {
              return (
                <div className="rf-content__line" key={key}>
                  <input
                    type="checkbox"
                    onChange={e => {
                      checkChange(e, data.id, !!filterCurrent.radio);
                    }}
                    data-id={data.id}
                    data-filter-name={filterCurrent.name}
                    data-name={data.name}
                    checked={data.check}
                  />
                  <span>{data.name}</span>
                  {data?.deleted && <div className="filter-popup__deleted" />}
                </div>
              );
            },
          )}
        </div>
      </div>
    </div>
  );
};
