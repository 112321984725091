import Api, {
  ITaskTimelessCreateForm,
  ITaskTimeless,
  TaskTimelessList,
} from 'sb_manufacturing_front_api';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  Dictionary,
  EntityId,
} from '@reduxjs/toolkit';
import { TaskTimeless } from 'sb_manufacturing_front_api';
import { ITaskTimelessUpdateForm } from 'sb_manufacturing_front_api';
import store from '~/redux/configureStore';
import { INewTask } from 'sb_manufacturing_front_api';
import moment from 'moment/moment';
import { getDayShifts } from '~/redux/modules/shiftsModule';
import { INewShiftsList } from 'sb_manufacturing_front_api';
import { FORMAT_MOMENT } from '~/helpers/constants';
import errorHandler from '~/utils/errorHandler';

export const tasksTimelessAdapter = createEntityAdapter<ITaskTimeless>({
  selectId: taskTimeless => taskTimeless.id,
});

export interface ITasksTimelessState {
  ids: EntityId[];
  entities: Dictionary<ITaskTimeless>;
}

export const deleteTaskTimeless = createAsyncThunk('deleteTaskTimeless', async (id: number) => {
  Api.deleteTaskTimeless(id)
    .then(result => {
      if (result.kind === 'ok') {
        return id;
      } else errorHandler(result);
    })
    .catch(result => {
      errorHandler(result);
    });
  return '';
});

export const createTaskTimeless = createAsyncThunk(
  'createTaskTimeless',
  async (input: ITaskTimelessCreateForm) => {
    const response: TaskTimeless = await Api.createTaskTimeless(input);
    const data: any = response;
    if (response.kind === 'ok') {
      if (data) {
        if (data?.data?.tasks.length) {
          data?.data?.tasks.map((task: INewTask) => {
            store.dispatch({
              type: `tasks/add`,
              payload: {
                ...task,
                start_date: data?.data?.start_date,
                end_date: moment(data?.data?.end_date)
                  .subtract(1, 'days')
                  .format(FORMAT_MOMENT.YYYYMMDD_HHMMSS),
                duration: data?.data.duration,
              },
            });
          });
        }
      }
    }
    return response;
  },
);

export const updateTaskTimeless = createAsyncThunk(
  'updateTaskTimeless',
  async (input: ITaskTimelessUpdateForm) => {
    const response: TaskTimeless = await Api.updateTaskTimeless(input);
    const data: any = response;
    if (response.kind === 'ok') {
      if (data) {
        if (data?.data?.tasks.length) {
          data?.data?.tasks.map((task: any) => {
            store.dispatch({
              type: `tasks/add`,
              payload: {
                ...task,
                start_date: data?.data?.start_date,
                end_date: moment(data?.data?.end_date)
                  .subtract(1, 'days')
                  .format(FORMAT_MOMENT.YYYYMMDD_HHMMSS),
                duration: data?.data.duration,
              },
            });
          });
        }
      }
    }
    return response;
  },
);

export const getTasksTimeless = createAsyncThunk('getTasksTimeless', async (date: string) => {
  const response: TaskTimelessList = await Api.getTaskTimelessList(date);
  const data: any = response;
  data.data.map((item: any) => {
    if (item.tasks.length) {
      item.tasks.map((task: any) => {
        store.dispatch({
          type: `tasks/add`,
          payload: {
            ...task,
            start_date: item?.start_date,
            end_date: moment(item?.end_date)
              .subtract(1, 'days')
              .format(FORMAT_MOMENT.YYYYMMDD_HHMMSS),
            duration: item.duration,
          },
        });
      });
    }
  });
  return response;
});

const tasksSlice = createSlice({
  name: 'tasksTimeless',
  initialState: tasksTimelessAdapter.getInitialState(),
  reducers: {
    create: tasksTimelessAdapter.setMany,
    update: tasksTimelessAdapter.upsertMany,
    remove: tasksTimelessAdapter.removeMany,
    clear: tasksTimelessAdapter.removeAll,
  },
  extraReducers: builder => {
    builder.addCase(getDayShifts.fulfilled, (state, action) => {
      const payload = action.payload as INewShiftsList;

      payload.timelessTasks.forEach(timelessTask => {
        timelessTask.end_date = moment(timelessTask.end_date)
          .subtract(1, 'days')
          .format(FORMAT_MOMENT.YYYYMMDD_HHMMSS);
      });

      tasksTimelessAdapter.removeAll(state);
      tasksTimelessAdapter.upsertMany(state, payload.timelessTasks);
    });
    builder.addCase(createTaskTimeless.fulfilled, (state, action) => {
      const payload = action.payload;
      if (payload === undefined || payload.kind !== 'ok') return;

      payload.data.end_date = moment(payload.data.end_date)
        .subtract(1, 'days')
        .format(FORMAT_MOMENT.YYYYMMDD_HHMMSS);

      tasksTimelessAdapter.upsertOne(state, payload.data);
    });
    builder.addCase(updateTaskTimeless.fulfilled, (state, action) => {
      const payload = action.payload;
      if (payload === undefined || payload.kind !== 'ok') return;

      payload.data.end_date = moment(payload.data.end_date)
        .subtract(1, 'days')
        .format(FORMAT_MOMENT.YYYYMMDD_HHMMSS);

      tasksTimelessAdapter.upsertOne(state, payload.data);
    });
    builder.addCase(deleteTaskTimeless.fulfilled, (state, action) => {
      if (action.payload) {
        tasksTimelessAdapter.removeOne(state, action.payload);
      }
    });
  },
});

export default tasksSlice.reducer;

export const { create, update, remove, clear } = tasksSlice.actions;
