import { prepareDateServerTime } from '~/helpers/convertToUnix';
import { FORMAT_MOMENT } from '~/helpers/constants';
import moment, { Moment } from 'moment';

const getDatesIsSame = (
  dateOne: Moment,
  dateTwo: Moment,
  timeZone: number,
): { start_date: string; end_date: string } => {
  const isSame = dateOne.isSame(dateTwo, 'dates');
  const defaultEndDate = prepareDateServerTime(
    dateTwo.format(FORMAT_MOMENT.YYYYMMDD_HHMMSS),
    timeZone,
  );
  const rightEndDate = prepareDateServerTime(
    moment(dateTwo).add(1, 'days').format(FORMAT_MOMENT.YYYYMMDD_HHMMSS),
    timeZone,
  );

  return {
    start_date: prepareDateServerTime(dateOne.format(FORMAT_MOMENT.YYYYMMDD_HHMMSS), timeZone),
    end_date: isSame ? rightEndDate : defaultEndDate,
  };
};

export default getDatesIsSame;
