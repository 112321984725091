import {
  Chart as ChartJS,
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { COLORS } from '~/helpers/constants';

ChartJS.register(BarElement, BarController, CategoryScale, LinearScale, Tooltip);

interface IChartDoughnut {
  data: any;
}

const ChartDoughnut = ({ data }: IChartDoughnut) => {
  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        backgroundColor: COLORS.WHITE,
        titleColor: COLORS.DARK,
        titleMarginBottom: 8,
        bodyColor: COLORS.DARK,
        padding: 8,
        cornerRadius: 4,
      },
    },
    maintainAspectRatio: false,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  return (
    <div className="chartBar">
      <Bar options={options} data={data} className="chartBar__bar" />
    </div>
  );
};

export default ChartDoughnut;
