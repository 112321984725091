import { Chart as ChartJS, ArcElement } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Legends from '~/components/statistic/Legends';

ChartJS.register(ArcElement);

interface IChartDoughnut {
  title?: string;
  data: any;
  legends: any;
  total?: string;
}

const ChartDoughnut = ({ title, data, legends, total }: IChartDoughnut) => {
  const options = {
    responsive: true,
    cutout: '75%',
    plugins: {
      tooltip: {
        enabled: false,
      },
    },
    layout: {
      padding: 0,
    },
    spacing: 1,
  };

  return (
    <div className="chartDoughnut">
      {!!title && <div className="chartDoughnut__title">{title}</div>}
      <div className="chartDoughnut__wrapper">
        <Legends items={legends} className="chartDoughnut__legend" />
        <div className="chartDoughnut__doughnut">
          <div className="chartDoughnut__total">{total}</div>
          <Doughnut options={options} width="600" height="200px" data={data} />
        </div>
      </div>
    </div>
  );
};

export default ChartDoughnut;
