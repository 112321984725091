import { useSelector } from 'react-redux';
import { selectCurrentWorkingShifts } from '~/redux/selectors/workingShiftsSelectors';
import { selectDay } from '~/redux/selectors/stateSelectors';
import moment from 'moment/moment';
import { FORMAT_MOMENT } from '~/helpers/constants';
import { INewWorkingShift } from 'sb_manufacturing_front_api';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';

interface IShiftConfig {
  offset: number;
  duration: number;
}

const getShiftConfig = (workShifts: INewWorkingShift[], timeZone: number): IShiftConfig => {
  const workShiftBegin = [...workShifts].sort((a, b) => {
    const timeA = moment(a.begin_time, FORMAT_MOMENT.HHMMSS)
      .add(timeZone, 'hour')
      .format(FORMAT_MOMENT.HHMMSS);
    const timeB = moment(b.begin_time, FORMAT_MOMENT.HHMMSS)
      .add(timeZone, 'hour')
      .format(FORMAT_MOMENT.HHMMSS);
    return timeA.localeCompare(timeB);
  });

  const workShiftEnd = [...workShifts].sort((a, b) => {
    const timeA = moment(a.end_time, FORMAT_MOMENT.HHMMSS)
      .add(timeZone, 'hour')
      .format(FORMAT_MOMENT.YYYYMMDD_HHMMSS);
    const timeB = moment(b.end_time, FORMAT_MOMENT.HHMMSS)
      .add(timeZone, 'hour')
      .format(FORMAT_MOMENT.YYYYMMDD_HHMMSS);
    return timeB.localeCompare(timeA);
  });

  const beginFormat = moment(workShiftBegin?.[0]?.begin_time, FORMAT_MOMENT.HHMMSS)
    .add(timeZone, 'hour')
    .format(FORMAT_MOMENT.HHMMSS);

  const beginTime = beginFormat.split(':').map(item => parseInt(item));

  const endFormat = moment(workShiftEnd?.[0]?.end_time, FORMAT_MOMENT.HHMMSS)
    .add(timeZone, 'hour')
    .format(FORMAT_MOMENT.HHMMSS);

  const endTime = endFormat.split(':').map(item => parseInt(item));

  const offset = beginTime?.[0] * 60 * 60 + beginTime?.[1] * 60;
  let duration = endTime?.[0] * 60 * 60 + endTime?.[1] * 60 - offset;

  if (
    moment(workShiftEnd?.[0]?.begin_time, FORMAT_MOMENT.HHMMSS)
      .add(timeZone, 'hour')
      .format('d') !==
    moment(workShiftEnd?.[0]?.end_time, FORMAT_MOMENT.HHMMSS).add(timeZone, 'hour').format('d')
  ) {
    duration += 24 * 60 * 60;
  }

  return {
    offset,
    duration,
  };
};

const createTimeline = (selectedDay: string, shifts: INewWorkingShift[], timeZone: number) => {
  const date = moment(selectedDay).clone().startOf('day');
  const beginTime = date.clone();
  const endTime = date.clone();

  const shiftConfig = getShiftConfig(shifts, timeZone);

  beginTime.add(shiftConfig.offset, 'seconds');
  endTime.add(shiftConfig.offset, 'seconds').add(shiftConfig.duration, 'seconds');

  return { beginTime: beginTime.unix(), endTime: endTime.unix() };
};

const useShiftTime = (): { beginTime: string; endTime: string } => {
  const workingShifts = useSelector(selectCurrentWorkingShifts);
  const timeZone = useSelector(selectCompanyOptions).time_zone;
  const selectedDay = useSelector(selectDay);

  const { beginTime, endTime } = createTimeline(selectedDay, workingShifts, timeZone);

  return {
    beginTime: moment.unix(beginTime).format(FORMAT_MOMENT.YYYYMMDD_HHMMSS),
    endTime: moment.unix(endTime).format(FORMAT_MOMENT.YYYYMMDD_HHMMSS),
  };
};

export default useShiftTime;
