import * as React from 'react';
import { INewTaskLog } from 'sb_manufacturing_front_api';
import { ReactComponent as ArrowNextSvg } from '~/assets/arrow-next.svg';
import { Image } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import { PdfPreview } from '~/components/pdf-viewer';
import { Marker } from '~/assets/marker';
import { Comment } from '~/assets/comment';
import { openPopup, closePopup } from '~/redux/modules/popupModule';
import { POPUPS_NAME } from '~/components/PopupManager';
import { getLocalTimeFormat } from '~/helpers/convertToUnix';
import { FORMAT_MOMENT } from '~/helpers/constants';
import { ReactComponent as EyeFileSvg } from '~/assets/eye-file.svg';

interface ITaskHistory {
  data: INewTaskLog[];
  onClickMore?: () => void;
  inside?: boolean;
  task?: any;
}

export const Entry = ({ title, date, description, images, zone_name }: INewTaskLog) => {
  const companyOptions = useSelector(selectCompanyOptions);
  const dateStr = React.useMemo(
    () => getLocalTimeFormat(date, companyOptions.time_zone, FORMAT_MOMENT.HHMM),
    [date],
  );
  const containerRef = React.useRef<HTMLDivElement>(null);
  const previewOptionsRef = React.useRef({
    mask: <EyeFileSvg />,
    getContainer: () => containerRef.current!,
  });

  return (
    <div className="history-entry">
      <p className="time">{dateStr}</p>
      <div className="description">
        <p>{title}</p>
        {zone_name ? (
          <p className="grey zone">
            <Marker /> {zone_name}
          </p>
        ) : (
          ''
        )}
        {description ? (
          <p className="grey comment">
            <Comment /> {description}
          </p>
        ) : (
          ''
        )}
        <div className="images" ref={containerRef}>
          {!!images?.length &&
            images.map((image, key) => (
              <div className="preview">
                <div className="preview__item square">
                  {image.split('.').reverse()[0] === 'pdf' ? (
                    <PdfPreview value={image} />
                  ) : (
                    <Image src={`${image}`} key={key} preview={previewOptionsRef.current} />
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export const TaskHistory = ({ data, inside, task }: ITaskHistory) => {
  const dispatch = useDispatch();

  const handelOpenHistory = () => {
    dispatch(closePopup());
    setTimeout(() => {
      dispatch(
        openPopup({
          name: POPUPS_NAME.HISTORY_TASK_POPUP,
          data: { logs: task?.logs, inside: inside, task: task },
        }),
      );
    }, 10);
  };

  return (
    <div className="history">
      <div className="content" style={{ paddingBottom: '20px' }}>
        <h3>История задачи</h3>
        {data.map((entry, index) => (
          <Entry key={index} {...entry} />
        ))}
      </div>
      <div className="delimeter" />
      <button className="more" onClick={handelOpenHistory}>
        Посмотреть всю историю
        <ArrowNextSvg />
      </button>
    </div>
  );
};
